<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h1> Estudios de Conexión </h1>
      </div>
      <div class="card">
        <Toast/>
        <EstudioForm/>
      </div>
    </div>
  </div>
</template>

<script>
import EstudioForm from "@/components/EstudioForm.vue";

export default {
  components: {EstudioForm},
}
;
</script>
