<script setup>
import {ref} from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
  {
    label: 'Home',
    items: [{label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'}]
  }, {
    label: 'Clientes',
    items: [
      {label: 'Gestionar', icon: 'pi pi-fw pi-box', to: '/clientes'}
    ]
  },{
    label: 'Estudios de conexión',
    items: [
      {label: 'Crear estudio', icon: 'pi pi-fw pi-plus', to: '/estudios'},
      {label: 'Listar estudios', icon: 'pi pi-fw pi-list', to: '/estudios-listar'},
      {label: 'Cargar datos de estudio', icon: 'pi pi-fw pi-upload', to: '/carga-datos-estudio'}
    ]
  }, {
    label: 'Flujos',
    items: [
      {label: 'Barras', icon: 'pi pi-fw pi-list', to: '/cotizacion/listar'},
      {label: 'Barras Fuera de Rango', icon: 'pi pi-fw pi-list', to: '/cotizacion/listar'},
      {label: 'Elementos', icon: 'pi pi-fw pi-list', to: '/cotizacion/listar'},
      {label: 'SobreCargados', icon: 'pi pi-fw pi-list', to: '/cotizacion/listar'}
    ]
  },{
    label: 'Cortos',
    items: [
      {label: 'Cargar', icon: 'pi pi-fw pi-box', to: '/clientes'},
      {label: 'Visualizar', icon: 'pi pi-fw pi-box', to: '/clientes'}
    ]
  },{
    label: 'Plantilla',
    items: [
      {label: 'Gestionar', icon: 'pi pi-fw pi-box', to: '/cotizacion'},
      {label: 'Barras', icon: 'pi pi-fw pi-list', to: '/cotizacion/listar'}
    ]
  }
]);
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>
