<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div>
          <h1> Cotizaciones</h1>
          <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <Dropdown
                    name="nombre"
                    v-model="cliente"
                    :options="clientes"
                    optionLabel="nombre"
                    filter
                />
                <label for="dropdown">Seleccione un cliente</label>
              </span>
            </div>
          </div>
          <div class="card p-fluid">
            <div class="formgrid grid">
              <div class="field col">
                <label for="name2">Nombre</label>
                <InputText id="name2" v-model="cliente.nombre" type="text"/>
              </div>
              <div class="field col">
                <label for="email2">Telefono</label>
                <InputText id="email2" v-model="cliente.telefono" type="text"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Agregar Producto" icon="pi pi-plus" severity="success" class="mr-2" @click="openNew"/>
            </div>
          </template>
          <template v-slot:end>
            <label>Valor Total: </label>
            <InputNumber id="total" v-model="valorTotal" disabled mode="currency" currency="USD"
                         locale="en-US" :maxFractionDigits="0"/>
          </template>
        </Toolbar>
        <DataTable ref="dt" :value="products" v-model:selection="selectedProducts" dataKey="id"
                   :paginator="true" :rows="10" :filters="filters"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,25]"
        >
          <template #header>
            <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
              <h4 class="m-0">Manage Products</h4>
            </div>
          </template>
          <Column field="nombre" header="Producto" sortable style="min-width:16rem"></Column>
          <Column field="cantidad" header="Cantidad" sortable style="min-width:12rem"></Column>
          <Column field="valor" header="Valor Unitario" sortable style="min-width:8rem">
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.precio) }}
            </template>
          </Column>
          <Column field="valorTotal" header="Valor Total" sortable style="min-width:8rem">
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.precio * slotProps.data.cantidad) }}
            </template>
          </Column>
          <Column :exportable="false" style="min-width:8rem">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                      @click="editProduct(slotProps.data)"/>
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                      @click="confirmDeleteProduct(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
        <div style="margin-top: 25px">
          <Button outlined rounded @click="generarFactura()">Guardar Cotización</Button>
        </div>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Detalle Producto" :modal="true"
          class="p-fluid">
    <div class="field">
      <label for="name">Producto</label>
      <Dropdown
          name="nombre"
          v-model="product"
          :options="productos"
          optionLabel="nombre"
          placeholder="Seleccione un producto"
          filter
      />
    </div>
    <div class="field">
      <label for="name">Nombre</label>
      <InputText id="name" v-model.trim="product.nombre" required="true"
                 :class="{ 'p-invalid': submitted && !product.nombre }"/>
      <small class="p-invalid" v-if="submitted && !product.nombre">El nombre es requerido.</small>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="price">Precio</label>
        <InputNumber id="price" v-model="product.precio" mode="currency" currency="USD" locale="en-US"
                     :class="{ 'p-invalid': submitted && !product.precio }" :required="true" :maxFractionDigits="0"/>
        <small class="p-invalid" v-if="submitted && !product.precio">El precio es requerido.</small>
      </div>
      <div class="field col">
        <label for="quantity">Cantidad</label>
        <InputNumber id="quantity" v-model="product.cantidad" integeronly/>
      </div>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
      <Button label="Confirmar" icon="pi pi-check" class="p-button-text" @click="saveProduct"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="product">Are you sure you want to delete <b>{{ product.name }}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" text @click="deleteProductDialog = false"/>
      <Button label="Yes" icon="pi pi-check" text @click="deleteProduct"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="product">Are you sure you want to delete the selected products?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" text @click="deleteProductsDialog = false"/>
      <Button label="Yes" icon="pi pi-check" text @click="deleteSelectedProducts"/>
    </template>
  </Dialog>
</template>

<script>

import {FilterMatchMode} from 'primevue/api';
import apiService from "@/service/api.service";

export default {
  data() {
    return {
      products: [],
      productos: [],
      numeroFactura: -1,
      valorTotal: 0,
      cliente: {},
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      defaultValue: 0,
      selectedProducts: null,
      factura: {
        cliente: {},
        productos: []
      },
      filters: {},
      submitted: false,
      clientes: [],
    }
  },
  created() {
    this.initFilters();
    this.obtenerClientes();
    this.obtenerProductos();
  },
  methods: {
    getDefaultQuantity() {
      return this.product.cantidad || 1;
    },

    imprimir(numeroFactura) {
      apiService.imprimir(numeroFactura).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.download = 'cotizacion' + this.cliente.nombre + '.pdf';
        link.click();

        window.URL.revokeObjectURL(url);
      })
          .catch(error => {
            console.error('Error al descargar el PDF', error);
          });

    },
    generarFactura() {
      this.obtenerClientes();
      this.obtenerProductos();
      if (this.products.length > 0) {
        this.factura.cliente = this.cliente;
        this.factura.productos = this.products;
        apiService.crearCotizacion(this.products, this.cliente).then(
            (response) => {
              this.numeroFactura = response.data.id
              this.valorTotal = 0;
              this.$toast.add({
                severity: 'success',
                summary: 'Terminado Exitoso!',
                detail: response.data.message,
                life: 5000
              });
              this.imprimir(response.data.id);
              const telefono = this.cliente.telefono;
              const mensaje = 'Hola, hago envio de la Cotizacion, Fiesta y Pastel';
              const urlWhatsAppWeb = `https://web.whatsapp.com/send?phone=${telefono}&text=${encodeURIComponent(mensaje)}`;
              window.open(urlWhatsAppWeb, '_blank');
              this.products = []
            },
            (error) => {
              let mensaje = error.response.data.left
              if (mensaje.mensaje) {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error en la Creación',
                  detail: error.response.data.left.mensaje,
                  life: 5000
                });
              } else {
                let codigo = mensaje.error.cause.errorCode
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error en la Creación',
                  detail: 'Contacte a Servicio técnico. Código: ' + codigo,
                  life: 5000
                });
              }
            });

      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0});
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.cantidad === undefined) {
        this.product.cantidad = 1;
      }
      if (this.product.id) {
        if (this.findIndexById(this.product.id) >= 0) {
          this.products[this.findIndexById(this.product.id)] = this.product;
        } else {
          this.products.push(this.product);
        }
      } else {
        this.product.id = this.createId();
        this.products.push(this.product);
      }
      this.valorTotal = this.products.reduce((precio, product) => precio + product.precio * product.cantidad, 0);
      this.productDialog = false;
      this.product = {};
    },
    editProduct(product) {
      this.product = {...product};
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter(val => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.valorTotal = this.products.reduce((precio, product) => precio + product.precio * product.cantidad, 0);
      this.product = {};
      this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let dt = new Date().getTime();
      const uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(val => !this.selectedProducts.includes(val));
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    obtenerClientes() {
      return apiService.getClientes().then(
          (response) => {
            this.clientes = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerProductos() {
      return apiService.getProductos().then(
          (response) => {
            this.productos = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
  }
}
</script>
