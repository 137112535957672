import interceptor from "@/service/interceptor";

const API_URL = 'http://api.digitalcodex.com.co/api/';

class ApiService {

    getProductos() {
        return interceptor.get(API_URL + 'estudios');
    }

    getTotalProductos() {
        return interceptor.get(API_URL + 'productos/total');
    }

    getClientes() {
        return interceptor.get(API_URL + 'clientes');
    }

    getDepartamentos() {
        return interceptor.get(API_URL + 'lugares/departamentos');
    }

    getMunicipios(ubicacion) {
        return interceptor.post(API_URL + 'lugares/municipios', ubicacion);
    }
    crearEstudio(estudio) {
        return interceptor.post(API_URL + 'estudios/crear', estudio)
    }
    editarEstudio(estudio) {
        return interceptor.post(API_URL + 'estudios/editar', estudio)
    }


    getEstudios() {
        return interceptor.get(API_URL + 'estudios');
    }


    getCotizaciones() {
        return interceptor.get(API_URL + 'cotizaciones');
    }

    newProducto(estudios) {
        return interceptor.post(API_URL + 'estudios/crear', estudios)
    }

    uploadFile(fileData) {
        return interceptor.post(API_URL + 'upload', fileData)
    }

    borrarProducto(producto) {
        return interceptor.post(API_URL + 'producto/borrar', producto)
    }

    borrarListaProducto(producto) {
        return interceptor.post(API_URL + 'productos/borrar', producto)
    }

    newCliente(cliente) {
        return interceptor.post(API_URL + 'clientes/crear', cliente)
    }

    borrarCliente(cliente) {
        return interceptor.post(API_URL + 'clientes/borrar', cliente)
    }

    borrarListaClientes(cliente) {
        return interceptor.post(API_URL + 'clientes/borrar-lista', cliente)
    }

    crearCotizacion(detalles, cotizacion) {
        const data = {
            detalles: detalles.map(detalle => ({
                producto: {
                    nombre: detalle.nombre,
                },
                cantidad: detalle.cantidad,
                precio: detalle.precio
            })), cotizacion: cotizacion
        }
        return interceptor.post(API_URL + 'cotizacion/crear', data)
    }

    imprimir(numero) {
        return interceptor.get(API_URL + 'imprimir/' + numero, {responseType: 'blob'});
    }
}

export default new ApiService();
