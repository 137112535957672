<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div>
          <h1> Cotizaciones</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12">
      <div class="card">

        <DataTable :value="productos" v-model:expandedRows="expandedRows" dataKey="numero"
                   :paginator="true" :rows="10" :filters="filters" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,25]">
          <template #header>
            <div>
              <Button icon="pi pi-plus" label="Expand All" @click="expandAll" class="mr-2 mb-2"/>
              <Button icon="pi pi-minus" label="Collapse All" @click="collapseAll" class="mb-2"/>
            </div>
          </template>
          <Column :expander="true" headerStyle="min-width: 3rem"/>
          <Column field="cliente.nombre" header="Cliente" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.cliente.nombre }}
            </template>
          </Column>
          <Column field="price" header="Valor Total" :sortable="true">
            <template #body="slotProps">
              {{ formatCurrency(obtenerTotalPorNumero(slotProps.data.numero)) }}
            </template>
          </Column>
          <Column :exportable="false" style="min-width:8rem">
            <template #body="slotProps">
              <Button icon="pi pi-print" class="p-button-rounded p-button-success mr-2"
                      @click="imprimir(slotProps.data.numero,slotProps.data.cliente.nombre)"/>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="p-3">
              <h5>Orders for {{ slotProps.data.cliente.nombre }}</h5>
              <DataTable :value="slotProps.data.detalles" responsiveLayout="scroll">
                <Column field="producto.nombre" header="Producto" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.producto.nombre }}
                  </template>
                </Column>
                <Column field="precio" header="Valor Unitario" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.producto.precio }}
                  </template>
                </Column>
                <Column field="cantidad" header="Cantidad" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.cantidad }}
                  </template>
                </Column>
                <Column field="total" header="Valor Total" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.cantidad * slotProps.data.producto.precio }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>

</template>

<script>

import {FilterMatchMode} from 'primevue/api';
import apiService from "@/service/api.service";

export default {
  data() {
    return {
      products: [],
      productos: [],
      expandedRows: [],
      numeroFactura: -1,
      sumasPorNumero: {},
      valorTotal: 0,
      cliente: {},
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      defaultValue: 0,
      selectedProducts: null,
      factura: {
        cliente: {},
        productos: []
      },
      filters: {},
      submitted: false,
      clientes: [],
    }
  },
  created() {
    this.initFilters();
    this.obtenerClientes();
    this.obtenerProductos();
  },
  methods: {
    getDefaultQuantity() {
      return this.product.cantidad || 1;
    },

    imprimir(numeroFactura, cliente) {
      apiService.imprimir(numeroFactura).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.download = 'cotizacion' + cliente + '.pdf';
        link.click();
        window.URL.revokeObjectURL(url);
      })
          .catch(error => {
            console.error('Error al descargar el PDF', error);
          });

    },
    obtenerTotalPorNumero(numero) {
      return this.sumasPorNumero[numero] || 0;
    },
    expandAll() {
      this.expandedRows = this.productos.filter((p) => p.numero);
    },
    collapseAll() {
      this.expandedRows = null;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let dt = new Date().getTime();
      const uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    obtenerClientes() {
      return apiService.getClientes().then(
          (response) => {
            this.clientes = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerProductos() {
      return apiService.getCotizaciones().then(
          (response) => {
            this.productos = response.data;


            // Calcular la suma por número de cotización
            response.data.forEach(cotizacion => {
              const numero = cotizacion.numero;
              const detalles = cotizacion.detalles;

              // Calcular el total sumando los precios de cada detalle
              const total = detalles.reduce((acc, detalle) => acc + (detalle.cantidad * detalle.precio), 0);

              // Almacenar la suma en el objeto sumasPorNumero
              if (!this.sumasPorNumero[numero]) {
                this.sumasPorNumero[numero] = total;
              } else {
                this.sumasPorNumero[numero] += total;
              }
            });
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
  }
}
</script>
