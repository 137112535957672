<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div>
          <h1> Estudios de conexión</h1>
        </div>
      </div>
      <div class="card">
        <DataTable :value="estudios" v-model:expandedRows="expandedRows" dataKey="id"
                   :paginator="true" :rows="10"
                   stripedRows
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,25]">
          <Column :expander="true" headerStyle="min-width: 3rem"/>
          <Column field="cliente.nombre" header="Cliente" :sortable="true"/>
          <Column field="nombre" header="Nombre" :sortable="true"/>
          <Column field="ubicacion.nombre" header="Ubicación" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.ubicacion.nombre }}, {{ slotProps.data.ubicacion.ubicacion.nombre }}
            </template>
          </Column>
          <Column field="tecnologia" header="Tecnología" :sortable="true"/>
          <Column field="fpo" header="Año (FPO)" :sortable="true"/>
          <Column field="finalField" header="Año Final" :sortable="true"/>
          <Column :exportable="true" style="min-width:8rem">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-raised p-button-rounded mr-2"
                      @click="edit(slotProps.data)"/>
              <Button icon="pi pi-upload" class="p-button-raised p-button-rounded mt-2 mr-2"
                      @click="edit(slotProps.data)"/>
              <Button icon="pi pi-trash" class="p-button-raised p-button-rounded p-button-warning mt-2"/>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="p-3 ">
              <div class="p-fluid formgrid grid">
                <div class="col-12 md:col-6">
                  <Fieldset legend="Localización" :toggleable="true" v-model:collapsed="isCollapsedL">
                    <div class="field col-12 md:col-12">
                      <label for="x">X</label>
                      <InputText :value="slotProps.data.y" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="y">Y</label>
                      <InputText id="y" :value="slotProps.data.y" type="text" disabled/>
                    </div>
                  </Fieldset>
                </div>
                <div class="col-12 md:col-6">
                  <Fieldset legend="Codificación" :toggleable="true" v-model:collapsed="isCollapsed">
                    <div class="field col-12 md:col-12">
                      <label for="inic">Inicial</label>
                      <InputText id="inic" v-model="slotProps.data.inic" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Consecutivo</label>
                      <InputText id="consc" v-model="slotProps.data.consc" type="text" disabled/>
                    </div>
                  </Fieldset>
                </div>
              </div>
              <h5>Alternativas {{ slotProps.data.nombre }}</h5>
              <DataTable :value="slotProps.data.alternativas" responsiveLayout="scroll">
                <Column field="nombre" header="Nombre" :sortable="true"></Column>
                <Column field="valor" header="Voltaje" :sortable="true"></Column>
                <Column field="distancia" header="Distancia" :sortable="true"></Column>
                <Column field="org" header="Operador de Red" :sortable="true"></Column>
                <Column field="g" header="Escenario" :sortable="true"></Column>

              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="estudioDialog" :style="{ width: '1000px' }" header="Detalle Producto" :modal="true" class="p-fluid">
    <EstudioForm :selected="estudio" :editar="true" @cerrar-modal="cerrarModal"></EstudioForm>
  </Dialog>
</template>

<script>
import apiService from "@/service/api.service";
import EstudioForm from "@/components/EstudioForm.vue";
export default {
  name: "ListarEstudios",
  components: {EstudioForm},
  data() {
    return {
      estudios: [],
      expandedRows: [],
      estudio: {},
      isCollapsed: true,
      isCollapsedL: true,
      estudioDialog: false,
    }
  },
  methods: {
    cerrarModal() {
      this.obtenerLista();
      this.estudioDialog = false;
    },
    edit(editCliente) {
      this.estudio = {...editCliente};
      this.estudioDialog = true;
    },
    obtenerLista() {
      return apiService.getEstudios().then(
          (response) => {
            this.estudios = response.data;
            console.log(JSON.stringify(this.estudios))
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
  },
  mounted() {
    this.obtenerLista()
  }

}

</script>



