<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h1> Clientes </h1>
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
              <Button label="Borrar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                      :disabled="!selectedClientes || !selectedClientes.length"/>
            </div>
          </template>
          <template v-slot:end>
            <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"/>
          </template>
        </Toolbar>
        <DataTable
            ref="dt"
            :value="clientes"
            v-model:selection="selectedClientes"
            dataKey="nit"
            :paginator="true"
            :rows="10"
            stripedRows
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} clientes"
            responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gestionar Clientes</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global'].value" placeholder="Search..."/>
                            </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="nombre" header="Nombre" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.nombre }}
            </template>
          </Column>
          <Column field="nombre" header="Nit" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Nit</span>
              {{ slotProps.data.nit }}
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                      @click="editCliente(slotProps.data)"/>
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                      @click="confirmDeleteCliente(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="clienteDialog" :style="{ width: '450px' }" header="Cliente Details" :modal="true"
                class="p-fluid">
          <div class="field">
            <label for="name">Nombre</label>
            <InputText id="name" v-model.trim="cliente.nombre" required="true" autofocus
                       :class="{ 'p-invalid': submitted && !cliente.nombre }"/>
            <small class="p-invalid" v-if="submitted && !cliente.nombre">El nombre es requerido.</small>
          </div>
          <div class="formgrid grid">
            <div class="field col">
              <label for="price">Nit</label>
              <InputNumber id="price" v-model="cliente.nit"
                           :class="{ 'p-invalid': submitted && !cliente.nit }" :required="true" :useGrouping="false" :precision="0"/>
              <small class="p-invalid" v-if="submitted && !cliente.nit">El Nit es requerido.</small>
            </div>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Confirmar" icon="pi pi-check" class="p-button-text" @click="saveCliente"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteClienteDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="cliente"
            >Are you sure you want to delete <b>{{ cliente.nit }}</b
            >?</span
            >
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteClienteDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteCliente"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteClientesDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="cliente">Are you sure you want to delete the selected clientes?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteClientesDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelectedClientes"/>
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'primevue/usetoast';
import apiService from '../service/api.service'

export default {
  data() {
    return {
      toast: useToast(),
      clientes: [],
      clienteDialog: false,
      deleteClienteDialog: false,
      deleteClientesDialog: false,
      cliente: {},
      selectedClientes: [],
      dt: [],
      filters: {},
      submitted: false
    };
  },
  methods: {
    openNew() {
      this.cliente = {};
      this.submitted = false;
      this.clienteDialog = true;
    },
    hideDialog() {
      this.clienteDialog = false;
      this.submitted = false;
    },
    saveCliente() {
      this.submitted = true;
      this.crearCliente();
    },
    editCliente(editCliente) {
      this.cliente = {...editCliente};
      this.clienteDialog = true;
    },
    confirmDeleteCliente(editCliente) {
      this.cliente = editCliente;
      this.deleteClienteDialog = true;
    },
    deleteCliente() {
      this.borrarCliente()
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.clientes.length; i++) {
        if (this.clientes[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    crearCliente() {
      return apiService.newCliente(this.cliente).then(
          () => {
            this.clientes.push(this.cliente);
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Cliente Created', life: 3000});
            this.clienteDialog = false;
            this.cliente = {};
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    borrarCliente() {
      return apiService.borrarCliente(this.cliente).then(
          (response) => {
            this.clientes = this.clientes.filter((val) => val.nombre !== this.cliente.nombre);
            this.deleteClienteDialog = false;
            this.cliente = {};
            this.toast.add({severity: 'success', summary: 'Successful', detail: response.data, life: 3000});
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    borrarListaCliente() {
      return apiService.borrarListaClientes(this.selectedClientes).then(
          (response) => {
            this.clientes = this.clientes.filter((val) => !this.selectedClientes.includes(val));
            this.deleteClientesDialog = false;
            this.selectedClientes = null;
            this.toast.add({severity: 'success', summary: 'Successful', detail: response.data, life: 3000});
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerClienteos() {
      return apiService.getClientes().then(
          (response) => {
            this.clientes = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    exportCSV() {
      if (this.$refs.dt) {
        this.$refs.dt.exportCSV();
      } else {
        console.error('No se encontró la referencia al DataTable');
      }

    },
    confirmDeleteSelected() {
      this.deleteClientesDialog = true;
    },
    deleteSelectedClientes() {
      this.borrarListaCliente()
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    }
  }
  ,
  beforeMount() {
    this.initFilters();
  }
  ,
  mounted() {
    this.obtenerClienteos();
  }
}
;
</script>
