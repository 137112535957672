import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
import AppLayout from "@/components/layout/AppLayout.vue";
import clientes from "@/views/Clientes.vue";
import cotizacion from "@/views/Cotizacion.vue";
import CotizacionListar from "@/views/CotizacionListar.vue";
import estudios from "@/views/pages/estudios/Estudios.vue";
import listarEstudios from "@/views/pages/estudios/ListarEstudios.vue";

const routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@/views/Dashboard.vue'),
                meta: {requiresAuth: true}
            },
        ]
    },
    {
        path: '/carga-datos-estudio',
        component: AppLayout,
        children: [
            {
                path: '/carga-datos-estudio',
                name: 'carga-datos-estudio',
                component: () => import('@/views/pages/estudios/CargaArchivosEstudio.vue'),
                meta: {requiresAuth: true}
            },
        ]
    },
    {
        path: '/productos',
        component: AppLayout,
        children: [
            {
                path: '/productos',
                name: 'productos',
                component: () => import('@/views/Productos.vue'),
                meta: {requiresAuth: true}
            },
        ]
    },
    {
        path: '/clientes',
        component: AppLayout,
        children: [
            {
                path: '/clientes',
                name: 'clientes',
                component: clientes,
                meta: {requiresAuth: true}
            },
        ]
    },
    {
        path: '/estudios',
        component: AppLayout,
        children: [
            {
                path: '/estudios',
                name: 'estudios',
                component: estudios,
                meta: {requiresAuth: true}
            },{
                path: '/estudios-listar',
                name: 'estudios-listar',
                component: listarEstudios,
                meta: {requiresAuth: true}
            },
        ]
    },
    {
        path: '/cotizacion',
        component: AppLayout,
        children: [
            {
                path: '/cotizacion',
                name: 'cotizacion',
                component: cotizacion,
                meta: {requiresAuth: true}
            }, {
                path: '/cotizacion/listar',
                name: 'cotizacion-listar',
                component: CotizacionListar,
                meta: {requiresAuth: true}
            },
        ]
    },
    {
        path: '/auth/login',
        name: 'login',
        component: () => import('@/views/pages/auth/Login.vue')
    },
    {
        path: '/auth/access',
        name: 'accessDenied',
        component: () => import('@/views/pages/auth/Access.vue')
    },
    {
        path: '/auth/error',
        name: 'error',
        component: () => import('@/views/pages/auth/Error.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        store.dispatch('validateToken')
        if (store.getters.IsLoggedIn) {
            next();
        } else {
            next({name: 'login'});
        }
    } else {
        next();
    }
});

export default router;
