<template>
  <div class="col-12">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-6">
        <label for="proyecto">Nombre del Proyecto</label>
        <InputText id="proyecto" v-model="estudio.nombre" type="text"/>
      </div>
      <div class="field col-12 md:col-6">
        <label for="cliente">Cliente</label>
        <Dropdown
            name="nombre"
            v-model="estudio.cliente"
            :options="clientes"
            optionLabel="nombre"
            placeholder="Seleccione un Cliente"
            filter
        />
      </div>
      <div class="field col-12 md:col-6">
        <label for="capacidad">Capacidad</label>
        <InputText id="capacidad" v-model="estudio.capacidad" type="text"/>
      </div>
      <div class="field col-12 md:col-6">
        <label for="tecnologia">Tecnología</label>
        <InputText id="tecnologia" v-model="estudio.tecnologia" type="text"/>
      </div>
      <div class="field col-12 md:col-6">
        <label for="fpo">Año Inicial (FPO)</label>
        <InputText id="fpo" v-model="estudio.fpo" type="text"/>
      </div>
      <div class="field col-12 md:col-6">
        <label for="final">Año Final</label>
        <InputText id="final" v-model="estudio.finalField" type="text"/>
      </div>
      <div class="col-12 md:col-12">
        <Fieldset legend="Ubicación">
          <div class="field col-12 md:col-12">
            <label for="departamento">Departamento</label>
            <Dropdown
                name="nombre"
                v-model="departamento"
                :options="departamentos"
                optionLabel="nombre"
                placeholder="Seleccione un Departamento"
                filter
                showClear
                @change="handleSelect"
            />
          </div>
          <div class="field col-12 md:col-12">
            <label for="municipio">Municipio</label>
            <Dropdown
                name="nombre"
                v-model="estudio.ubicacion"
                :options="municipios"
                optionLabel="nombre"
                :disabled="enableMunicipios"
                placeholder="Seleccione un Municipio"
                showClear
                filter
            />
          </div>
        </Fieldset>
      </div>
      <div class="col-12 md:col-6">
        <Fieldset legend="Localización">
          <div class="field col-12 md:col-12">
            <label for="x">X</label>
            <InputText id="x" v-model="estudio.x" type="text"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="y">Y</label>
            <InputText id="y" v-model="estudio.y" type="text"/>
          </div>
        </Fieldset>
      </div>
      <div class="col-12 md:col-6">
        <Fieldset legend="Codificación">
          <div class="field col-12 md:col-12">
            <label for="inic">Inicial</label>
            <InputText id="inic" v-model="estudio.inic" type="text"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="consc">Consecutivo</label>
            <InputText id="consc" v-model="estudio.consc" type="text"/>
          </div>
        </Fieldset>
      </div>
      <div class="field col-12 md:col-12">
        <Fieldset legend="Alternativas" :toggleable="true">
          <Stepper orientation="vertical" :active-step="alternativaActiva">
            <StepperPanel v-for="(alternativa, index) in alternativas" :key="index"
                          :header="`Alternativa ${index + 1}`">
              <template #content="{ prevCallback,nextCallback }">
                <div class="col-12">
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-6">
                      <label for="nombre">Nombre</label>
                      <InputText id="nombre" type="text" v-model="alternativa.nombre"/>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="voltaje">Voltaje</label>
                      <InputText id="voltaje" type="text" v-model="alternativa.valor"/>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="distancia">Distancia</label>
                      <InputText id="distancia" type="text" v-model="alternativa.distancia"/>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="or">Operador Red (Or)</label>
                      <InputText id="or" type="text" v-model="alternativa.org"/>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="g">Escenario (G)</label>
                      <InputText id="g" type="text" v-model="alternativa.g"/>
                    </div>
                  </div>
                </div>
                <div class="p-fluid formgrid grid">
                  <div v-if="!index < 1 "
                       :class="(alternativas.length > 1 && index+1 !== alternativas.length) ? 'field col-6 md:col-6':'field col-6 md:col-6'">
                    <Button label="Anterior" icon="pi pi-arrow-left" iconPos="left"
                            @click="prevCallback"/>
                  </div>
                  <div v-if="alternativas.length > 1 && index+1 !== alternativas.length"
                       :class="(alternativas.length > 1 && index+1 !== alternativas.length) ? 'field col-6 md:col-6':'field col-6 md:col-6'">
                    <Button label="Siguiente"
                            icon="pi pi-arrow-right" iconPos="right"
                            @click="nextCallback"/>
                  </div>
                  <div v-if="(index===0 && alternativas.length === 1) || index === alternativas.length-1"
                       :class="(alternativas.length > 2 && index+1 === alternativas.length)  ? 'field col-3 md:col-3':'field col-6 md:col-6'">
                    <Button label="Agregar"
                            icon="pi pi-plus"
                            iconPos="right" @click="agregarPanel"></Button>
                  </div>
                  <div v-if="(index===0 && alternativas.length === 1) || index === alternativas.length-1"
                       :class="(alternativas.length > 2 && index+1 === alternativas.length)  ? 'field col-3 md:col-3':'field col-3 md:col-3'">
                    <Button v-if="(alternativas.length > 2 && index+1 === alternativas.length)" label="Eliminar"
                            icon="pi pi-minus"
                            iconPos="right" @click="eliminarPanel"></Button>
                  </div>
                </div>
              </template>
            </StepperPanel>
          </Stepper>
        </Fieldset>
      </div>
      <div class="field col-12 md:col-4 md:col-offset-4">
        <Button v-if="!editar" label="Guardar" icon="pi pi-save" iconPos="right" @click="grabarEstudio"/>
        <Button v-if="editar" label="Editar" icon="pi pi-save" iconPos="right" @click="editarEstudio"/>
      </div>
    </div>
  </div>
</template>
<script>
import apiService from "@/service/api.service";
import {useToast} from "primevue/usetoast";

export default {
  name: "EstudioForm",
  props: [
    'selected',
    'editar'
  ],
  data() {
    return {
      toast: useToast(),
      estudio: {},
      submitted: false,
      clientes: [],
      cliente: {},
      alternativas: [{}],
      alternativaActiva: 0,
      departamentos: [],
      municipios: [],
      enableMunicipios: true,
      departamento: {}
    }
  },
  methods: {
    agregarPanel() {
      this.crearAlternativa();
      this.alternativaActiva = this.alternativas.length - 1
    },
    crearAlternativa() {
      this.alternativas.push({});
    },
    eliminarPanel() {
      this.alternativas.splice(this.alternativas.length - 1, 1);
      this.alternativaActiva = this.alternativas.length - 1
    },
    handleSelect() {
      if (this.departamento !== null) {
        this.enableMunicipios = false;
      } else {
        this.enableMunicipios = true;
        this.estudio.municipio = {};
      }
      this.obtenerMunicipio()
    },
    obtenerClientes() {
      return apiService.getClientes().then(
          (response) => {
            this.clientes = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerDepartamentos() {
      return apiService.getDepartamentos().then(
          (response) => {
            this.departamentos = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    grabarEstudio() {
      this.estudio.alternativas = this.alternativas;
      return apiService.crearEstudio(this.estudio).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Cliente Created', life: 3000});
            this.estudio = {};
            this.$router.push('/estudios-listar')
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    editarEstudio() {
      this.estudio.alternativas = this.alternativas;
      return apiService.editarEstudio(this.estudio).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Cliente Created', life: 3000});
            this.$emit('cerrar-modal');
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerMunicipio() {
      return apiService.getMunicipios(this.departamento).then(
          (response) => {
            this.municipios = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    }
  },
  mounted() {
    this.obtenerClientes();
    this.crearAlternativa();
    this.obtenerDepartamentos();
    if (this.editar) {
      this.enableMunicipios = false;
      this.estudio = this.selected;
      this.alternativas = this.selected.alternativas
      this.departamento = this.selected.ubicacion.ubicacion
      this.obtenerMunicipio();
      this.estudio.ubicacion = this.selected.ubicacion

    }
  }
}
</script>


